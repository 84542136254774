import React, { useEffect, useRef } from "react"
import { withPrefix } from "gatsby"
import gsap from "gsap"
import { connect } from "react-redux"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const JocondeSvg = ({ scrolltriggerReady, windowWith }) => {
  let width
  let height
  let image = useRef()

  if (typeof window !== `undefined`) {
    height = window.innerHeight / 2
    width = window.innerWidth
  }

  //tween
  let jocondeTween = useRef(null)

  useEffect(() => {
    if (scrolltriggerReady) {
      ScrollTrigger.getById("joconde-animation") &&
        ScrollTrigger.getById("joconde-animation").kill()
      jocondeTween.current = gsap.to(image, {
        scrollTrigger: {
          id: "joconde-animation",
          scrub: true,
          trigger: image,
          start: "top 50%",
          end: "bottom 50%",
        },
        yPercent: -15,
      })
    }
  }, [scrolltriggerReady, windowWith])

  useEffect(() => {
    return () => [jocondeTween.current && jocondeTween.current.kill()]
  }, [])
  return (
    <>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        viewBox="0 0 1623.2877 2455.2226"
        style={{ transform: "scale(2)", marginLeft: "6vw", marginTop: "10vw" }}
      >
        <defs>
          <clipPath id="clip-path">
            <path
              d="M1079.6037,1058.6522q14.7759,44.3278,121.1705,44.3279v86.193H659.97V1102.98H789.9912q42.8272,0,42.8464-27.9743,0-4.3866-4.4251-16.2L769.3087,899.8108h-453.63l-54.66,147.2208a55.0567,55.0567,0,0,0-2.9629,17.6618q0,38.2964,65.01,38.2867h91.6186v86.193H11.31v-87.4243q62.0475,0,91.5993-10.3317t36.94-31.0526L509.2476,90.8269H723.5ZM354.1,790.2225H732.3689L544.706,270.1007Z"
              style={{ fill: "none" }}
            />
          </clipPath>
        </defs>
        <g style={{ clipPath: "url(#clip-path)" }}>
          <image
            xlinkHref={withPrefix("/text-clip/joconde-crop.jpg")}
            clipPath="url(#mask-facade)"
            preserveAspectRatio="none"
            height="100%"
            width="100%"
            ref={el => (image = el)}
          ></image>
        </g>
      </svg>
    </>
  )
}

const mapStateToProps = ({ scrolltriggerReady, windowWith }) => {
  return { scrolltriggerReady, windowWith }
}

export default connect(mapStateToProps, null)(JocondeSvg)
