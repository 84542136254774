import React, { useEffect, useRef, useState } from "react"
import { Image } from "../gatsby-images/image"
import gsap from "gsap"
import { connect } from "react-redux"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import TransitionLink from "gatsby-plugin-transition-link"
import { Trans } from "@lingui/macro"

const InsideActivities = ({
  title,
  description,
  color,
  src,
  showTransitionBanner,
  hideTransitionBanner,
  updateActivityTitle,
  imageSrc,
  updateBackgroundColor,
  resetOffset,
  scrolltriggerReady,
  language,
}) => {
  let photo = useRef()
  let descriptionContainer = useRef()
  let titleContainer = useRef()
  let sectionContainer = useRef()

  //tweens
  let photoTween = useRef(null)
  let colorTween = useRef(null)

  useEffect(() => {
    const photoConst = photo.children[0].children[0]
    const titleConst = titleContainer
    const descriptionContainerConst = descriptionContainer
    const photoHeight = photoConst.getBoundingClientRect().height
    if (scrolltriggerReady) {
      gsap.set(photoConst, {
        yPercent: 10,
      })
      const windowHeight = window.innerHeight
      ScrollTrigger.getById(`photo-activitypage-${src}`) &&
        ScrollTrigger.getById(`photo-activitypage-${src}`).kill()
      photoTween.current = gsap.to([photoConst, titleConst], {
        id: `photo-activitypage-${src}`,
        y: -photoHeight * 0.25,
        ease: "none",
        scrollTrigger: {
          trigger: photoConst,
          start: "top 100%",
          end: windowHeight ? `${windowHeight}px` : "bottom top",
          scrub: true,
        },
      })

      ScrollTrigger.getById(`color-activitypage-${src}`) &&
        ScrollTrigger.getById(`photo-activitypage-${src}`).kill()
      colorTween.current = ScrollTrigger.create({
        id: `photo-activitypage-${src}`,
        trigger: sectionContainer,
        start: "top 50%",
        end: "bottom 50%",
        onEnter: () => {
          updateActivityTitle(title)
          updateBackgroundColor(color)
        },
        onEnterBack: () => {
          updateActivityTitle(title)
          updateBackgroundColor(color)
        },
        onLeaveBack: () => {
          color == "#748656" && updateBackgroundColor("#F4EBE3")
        },
      })
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      photoTween.current && photoTween.current.kill()
      colorTween.current && colorTween.current.kill()
    }
  }, [])
  return (
    <div
      className="activity-section"
      id={src}
      ref={el => (sectionContainer = el)}
    >
      <div
        style={{ overflow: "hidden", position: "relative" }}
        ref={el => (photo = el)}
      >
        <div
          className="photo"
          // ref={el => photo = el}
        >
          <Image src={imageSrc} />
        </div>
      </div>
      <div ref={el => (titleContainer = el)} className="title">
        {title}
      </div>
      <div className="description" ref={el => (descriptionContainer = el)}>
        {description}
      </div>
      {title == "Spa" && (
        <div style={{ width: "100%" }} onClick={() => showTransitionBanner()}>
          <TransitionLink
            to={`/${language}/spa`}
            exit={{
              delay: 2,
            }}
            entry={{
              trigger: ({ node, e, exit, entry }) => {
                hideTransitionBanner()
                resetOffset(true)
              },
            }}
          >
            <div className="spa-link-activities">
              <Trans>Plus d'infos</Trans>
            </div>
          </TransitionLink>
        </div>
      )}
    </div>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    updateActivityTitle: title =>
      dispatch({ type: `UPDATE_ACTIVITY_TITLE`, payload: title }),
    updateBackgroundColor: color =>
      dispatch({ type: `UPDATE_ROOM_LIST_COLOR`, payload: color }),
    showTransitionBanner: () => dispatch({ type: `IMG_NOT_LOADED` }),
    hideTransitionBanner: () => dispatch({ type: `IMG_LOADED` }),
    resetOffset: bool => dispatch({ type: "RESET_OFFSET", payload: bool }),
  }
}

const mapStateToProps = ({ language, scrolltriggerReady }) => {
  return { language, scrolltriggerReady }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsideActivities)
