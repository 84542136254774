import React from "react"
import Joconde from "./JocondeSvg"
import { Trans } from "@lingui/macro"

const TextIntroduction = () => {
  return (
    <div className="text-introduction row">
      <div className="col-sm-4 letter d-none d-sm-block">
        <Joconde />
      </div>
      <div className="col-10 offset-1  col-sm-7 text-area">
        <div className="title">
          <Trans>Amboise, ville royale aux mille merveilles</Trans>
        </div>
        <div className="text">
          <div style={{ marginRight: "2vw" }}>
            <Trans>
              AU CHARME RABELAISIEN est situé au cœur de la région des Châteaux
              de la Loire. Au départ de notre hôtel, offrez-vous une plongée
              dans l’histoire de France lors de votre escapade en bord de Loire.
              Les superbes paysages du bord de Loire et les bâtiments
              historiques
            </Trans>
          </div>
          <div>
            <Trans>
              et architecturaux chargés d’histoire font du Val de Loire un
              territoire riche et incontournable, inscrit à la liste du
              Patrimoine Mondial de l’UNESCO. La région s’étend sur une aire de
              800 km2, le long de la Loire sur 4 départements.
            </Trans>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextIntroduction
