import React, { useRef, useEffect, useState } from "react"
import Banner from "../components/activities/Banner"
import ImagesIntroduction from "../components/activities/ImagesIntroduction"
import ImagesArr from "../data/activities-images.json"
import ActivitiesContainer from "../components/activities/ActivitiesContainer"
import TextIntroduction from "../components/activities/TextIntroduction"
import { connect } from "react-redux"
import SEO from "../components/seo"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const langSeo = {
  fr: {
    title: "Hôtel Au Charme Rabelaisien **** | Activités",
    description:
      "Hotel Au Charme Rabelaisien, votre hôtel à 2h de Paris au milieu des châteaux de la Loire: Chenonceau, Chambord, Chaumont, Azay-le-Rideau vous invite à découvrir les activités autour de chez nous.",
  },
  en: {
    title: "Hôtel Au Charme Rabelaisien **** | Activities",
    description:
      "Hotel Au Charme Rabelaisien, your hotel 2 hours from Paris in the middle of the Loire châteaux: Chenonceau, Chambord, Chaumont, Azay-le-Rideau invites you to discover the activities around us.",
  },
  ru: {
    title: "Hôtel Au Charme Rabelaisien **** | Activities",
    description:
      "Hotel Au Charme Rabelaisien, your hotel 2 hours from Paris in the middle of the Loire châteaux: Chenonceau, Chambord, Chaumont, Azay-le-Rideau invites you to discover the activities around us.",
  },
}

const Activities = ({
  updateRoomsListColor,
  navColor,
  updateNavColor,
  updateBackgroundColor,
  roomListColor,
  language,
  scrollOffset,
  updateShowArrowDown,
  scrolltriggerReady,
}) => {
  let container = useRef()
  let urlLanguage = "fr"

  //show arrow down
  let tempScrollTriggerActivitiesRef = useRef(null)
  //state
  const [pageHeight, setPageHeight] = useState("")

  useEffect(() => {
    if (scrolltriggerReady) {
      if (pageHeight && pageHeight - scrollOffset < window.innerHeight + 100) {
        updateShowArrowDown(false)
      } else {
        updateShowArrowDown(true)
      }
    }
  }, [scrollOffset, scrolltriggerReady])

  useEffect(() => {
    navColor !== "black" && updateNavColor()
    roomListColor && updateRoomsListColor("#F4EBE3")
    setTimeout(() => {
      updateBackgroundColor("#EFE8E3")
    }, 200)
    const tempScrollTrigger = ScrollTrigger.create({ id: "temp-rooms" })
    setPageHeight(tempScrollTrigger["scroller"]["scrollHeight"])
    tempScrollTriggerActivitiesRef.current = tempScrollTrigger

    return () => {
      updateRoomsListColor("white")
      tempScrollTriggerActivitiesRef.current &&
        tempScrollTriggerActivitiesRef.current.kill()
    }
  }, [])

  if (typeof window !== "undefined") {
    const url = window.location.pathname

    if (url.includes("fr")) {
      urlLanguage = "fr"
    } else {
      urlLanguage = "en"
    }
  }

  // useEffect(() => {
  //     const containerConst = container
  //     gsap.to(containerConst, {backgroundColor: activityBackgroundColor})
  // }, [activityBackgroundColor])
  return (
    <div className="activities-page" ref={el => (container = el)}>
      <SEO
        title={langSeo[urlLanguage]["title"]}
        description={langSeo[urlLanguage]["description"]}
      />
      <Banner />
      <ImagesIntroduction images={ImagesArr} />
      <TextIntroduction />
      <ActivitiesContainer />
    </div>
  )
}

const mapStateToProps = ({
  activityBackgroundColor,
  navColor,
  roomListColor,
  language,
  scrollOffset,
  updateShowArrowDown,
  scrolltriggerReady,
}) => {
  return {
    activityBackgroundColor,
    navColor,
    roomListColor,
    language,
    scrollOffset,
    updateShowArrowDown,
    scrolltriggerReady,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateNavColor: () =>
      dispatch({ type: `UPDATE_NAV_COLOR`, payload: "black" }),
    updateBackgroundColor: color =>
      dispatch({ type: `UPDATE_ACTIVITY_COLOR`, payload: color }),
    updateRoomsListColor: color =>
      dispatch({ type: "UPDATE_ROOM_LIST_COLOR", payload: color }),
    updateShowArrowDown: bool =>
      dispatch({ type: "UPDATE_SHOW_ARROW_DOWN", payload: bool }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities)
