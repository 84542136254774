import { Trans } from "@lingui/macro"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Image } from "../gatsby-images/image"

const Banner = ({ scrolltriggerReady }) => {
  let banner = useRef(null)
  let title = useRef(null)

  //tween
  let bannerTween = useRef(null)

  useEffect(() => {
    if (scrolltriggerReady) {
      const titleConst = title
      const bannerConst = banner
      ScrollTrigger.getById("banner-activity-page") &&
        ScrollTrigger.getById("banner-activity-page").kill()
      bannerTween.current = gsap.to(titleConst, {
        yPercent: 40,
        ease: "none",
        scrollTrigger: {
          id: "banner-activity-page",
          trigger: bannerConst,
          start: "10% 10%", // the default values
          end: "bottom",
          scrub: true,
        },
      })
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      bannerTween.current && bannerTween.current.kill()
    }
  }, [])
  return (
    <>
      <div
        className="activities-banner col-sm-6 offset-sm-3"
        ref={el => (banner = el)}
      >
        <Image src={4} />
        <h2 ref={el => (title = el)}>
          <Trans>Activités</Trans>
        </h2>
      </div>
      <div className="under-banner-title offset-1">
        <Trans>La Magie de la Vallée de la Loire</Trans>
      </div>
    </>
  )
}

const mapStateToProps = ({ scrolltriggerReady }) => {
  return { scrolltriggerReady }
}

export default connect(mapStateToProps, null)(Banner)
