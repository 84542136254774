import React, { useRef, useEffect, useState } from "react"
import { Image } from "../gatsby-images/image"
import gsap from "gsap"

const ImagesIntroduction = ({ images }) => {
  let imgArr = useRef([])
  let wrapper = useRef(null)
  const [initialSetUp, setInitialSetUp] = useState(false)

  const addToImgArr = el => {
    if (el && !imgArr.current.includes(el)) {
      imgArr.current.push(el)
    }
  }
  useEffect(() => {
    if (!initialSetUp) {
      setInitialSetUp(true)
      const imgArrConst = imgArr.current
      let windowWith = window.innerWidth

      var boxWidth = 0,
        totalWidth = wrapper.getBoundingClientRect().width, //  * n of boxes
        no01 = imgArrConst,
        dirFromRight = "-=" + totalWidth

      let wrapperHeight = wrapper.getBoundingClientRect().height

      imgArrConst.map(img => {
        const imgHeight = img.getBoundingClientRect().height
        gsap.set(img, { y: wrapperHeight / 2 - imgHeight / 2 })
      })
      //infinite images
      gsap.set(imgArrConst, { left: 0 })
      let imagesPosition = 0
      const screenWidth = window.innerWidth
      // let initialPosition = parseFloat(imgArrConst[0].getAttribute('data-percent'))
      let totalW = 0
      imgArrConst.map(img => {
        let perCent = parseFloat(img.getAttribute("data-percent"))
        totalW += perCent * screenWidth
      })

      imgArrConst.map(img => {
        let perCent = parseFloat(img.getAttribute("data-percent"))
        let leftPosition = imagesPosition * screenWidth
        gsap.set(img, { left: leftPosition })
        imagesPosition += perCent
        let leftTravel = -screenWidth * imagesPosition
        let rightOverflow = totalW + leftTravel
        var mod = gsap.utils.wrap(leftTravel, rightOverflow)
        gsap.to(img, {
          x: -totalW,
          modifiers: {
            x: x => {
              // let elementIndex = parseInt(y.getAttribute('data-index'))
              // let minXValue = -totalWidth - initialXPositionOfElement[elementIndex] + windowWith
              // var mod = gsap.utils.wrap(minXValue, windowWith)
              return mod(parseFloat(x)) + "px"
            },
          },
          duration: 30,
          ease: "none",
          repeat: -1,
        })
      })
    }
  })

  return (
    <div
      style={{ display: "inline-flex", height: "30vw", minHeight: "60vw" }}
      ref={el => (wrapper = el)}
    >
      {images.map((imgObj, i) => {
        return (
          <div
            data-index={i}
            data-percent={imgObj.widthPerCent}
            className="img-introduction"
            key={imgObj.src}
            ref={el => addToImgArr(el)}
            style={{
              // height: imgObj.height,
              width: imgObj.width,
            }}
          >
            <Image src={imgObj.src} />
          </div>
        )
      })}
    </div>
  )
}

export default ImagesIntroduction
