import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import Scrollbar from "smooth-scrollbar"
import InsideActivities from "./InsideActivities"

const textFixBox = [
  {
    title: { fr: "Sur Place", en: "Inside the Hotel", ru: "на месте" },
    number: 1,
    listActivities: {
      fr: [
        {
          activityNumber: "01",
          title: "Spa",
          src: "spa",
          description:
            "Pour votre détente et votre bien-être, l’hôtel ACR s’est doté d’un espace bien-être avec jaccuzzi et sauna à accès privatif.",
          imageSrc: "massage-activity",
          bgColor: "#748656",
        },
        {
          activityNumber: "02",
          title: "Bar",
          src: "bar",
          description:
            "À la belle saison, installez-vous à l'extérieur, dans notre jardin paysagé, pour déguster un cocktail à la tombée du jour.",
          imageSrc: "cocktail-acr",
          bgColor: "#883645",
        },
        {
          activityNumber: "03",
          title: "Piscine",
          src: "piscine",
          description:
            "Au cœur de notre jardin paysagé, profitez de la piscine extérieure chauffée du 15 juin au 15 Septembre pour vous détendre après un voyage au retour d'une journée d'excursion, ou tout simplement pour vous laisser aller à la rêverie.",
          imageSrc: "piscine-landscape",
          bgColor: "#4854A2",
        },
      ],
      en: [
        {
          activityNumber: "01",
          title: "Spa",
          src: "spa",
          description:
            "For your relaxation and well-being, the ACR hotel has a wellness area with jacuzzi and sauna with private access",
          imageSrc: "massage-activity",
          bgColor: "#748656",
        },
        {
          activityNumber: "02",
          title: "Bar",
          src: "bar",
          description:
            "When the weather is nice, sit outside in our landscaped garden to enjoy a cocktail at nightfall",
          imageSrc: "cocktail-acr",
          bgColor: "#883645",
        },
        {
          activityNumber: "03",
          title: "Pool",
          src: "pool",
          description:
            "In the heart of our landscaped garden, enjoy the heated outdoor swimming pool from June 15 to September 15 to relax after a trip, on your return from a day's excursion or simply to let yourself go to daydreaming",
          imageSrc: "piscine-landscape",
          bgColor: "#4854A2",
        },
      ],
      ru: [
        {
          activityNumber: "01",
          title: "Спа",
          src: "spa",
          description:
            "Для Вашего расслабления и хорошего самочувствия, отель ACR предлагает зону отдыха с джакузи и сауной личного доступа",
          imageSrc: "massage-activity",
          bgColor: "#748656",
        },
        {
          activityNumber: "02",
          title: "Бар",
          src: "bar",
          description:
            "В любую погоду Вы можете расположиться в ландшафтном саду и насладиться коктейлем в конце дня.",
          imageSrc: "cocktail-acr",
          bgColor: "#883645",
        },
        {
          activityNumber: "03",
          title: "Бассейн",
          src: "piscine",
          description:
            "В самом сердце нашего ландшафтного сада воспользуйтесь открытым бассейном с подогревом с 15 июня по 15 сентября, чтобы расслабиться по возвращении с экскурсии или прогулки. Позвольте себе погрузиться в мечты.",
          imageSrc: "piscine-landscape",
          bgColor: "#4854A2",
        },
      ],
    },
  },
  {
    title: { fr: "Aux Alentours", en: "Arround the Hotel", ru: "рядом" },
    number: 2,
    listActivities: {
      fr: [
        {
          activityNumber: "1",
          title: "Châteaux",
          src: "chateaux",
          description:
            "AU CHARME RABELAISIEN est situé au cœur de la région des Châteaux de la Loire. Au départ de notre hôtel, offrez-vous une plongée dans l’histoire de France lors de votre escapade en bord de Loire.",
          imageSrc: "chambord-sunrise",
          bgColor: "#FB9149",
        },
        {
          activityNumber: "02",
          title: "Vélo",
          src: "velo",
          description:
            "Suivre la route des Châteaux de la Loire, naviguer sur le fleuve ou emprunter les chemins en bord de Loire à vélo : les possibilités sont nombreuses pour découvrir la région, à deux, en famille ou entre amis. ",
          imageSrc: "bicycle-forest-landscape",
          bgColor: "#727378",
        },
        {
          activityNumber: "03",
          title: "Montgolfière",
          src: "montgolfiere",
          description:
            "Volez en montgolfière au-dessus de la Loire et ses châteaux, entre Amboise, Chenonceau et Chambord. Vivez une expérience magique dans un paysage d'exception.",
          imageSrc: "hot-air-balloon-landscape",
          bgColor: "#A26848",
        },
        {
          activityNumber: "03",
          title: "Zoo de Beauval",
          src: "beauval",
          imageSrc: "beauval",
          description:
            "À moins de 30 minutes, le ZooParc de Beauval présente, avec quelques 800 espèces, la plus grande diversité animalière de France ! Véritablement extraordinaires, certaines espèces ont particulièrement marqué l'histoire des lieux…",
          bgColor: "#B0B0B0",
        },
        {
          activityNumber: "04",
          title: "Golf",
          src: "golf",
          description:
            "D’une renaissance à l’autre, jouer au golf sur les bords de la Loire. Les châteaux et parcours de golf qui s’y succèdent rivalisent de beauté et d’audace architectura­le. Et au cœur de ses forêts, où foisonne le gibier, se niche le par­cours du Golf de Touraine, un des golfs les plus beaux de France",
          imageSrc: "golf-acr",
          bgColor: "#408058",
        },
      ],
      en: [
        {
          activityNumber: "01",
          title: "Castle",
          src: "castle",
          description:
            "At the heart of the Loire Valley Castles region, Au Charme Rabelaisien is surrounded by medieval castles and royal gardens on the banks of the majestic Loire.",
          imageSrc: "chambord-sunrise",
          bgColor: "#FB9149",
        },
        {
          activityNumber: "02",
          title: "Bicycle",
          src: "bicycle",
          description:
            "Follow the Châteaux de la Loire route, navigate the river or take the paths along the Loire by bike: there are many possibilities to discover the region, as a couple, with family or friends",
          imageSrc: "bicycle-forest-landscape",
          bgColor: "#727378",
        },
        {
          activityNumber: "03",
          title: "Hot Air Balloon",
          src: "hot-air-balloon",
          description:
            "Fly in a hot-air balloon over the Loire River and its castles, between Amboise, Chenonceau and Chambord. Live a magical experience in an exceptional landscape",
          imageSrc: "hot-air-balloon-landscape",
          bgColor: "#A26848",
        },
        {
          activityNumber: "03",
          title: "Zoo de Beauval",
          src: "beauval",
          description:
            "Less than 30 minutes from Amboise, the ZooParc de Beauval is, with some 800 species, the greatest animal diversity in France! Truly extraordinary, certain species have particularly marked the history of the place ...",
          imageSrc: "beauval",
          bgColor: "#B0B0B0",
        },
        {
          activityNumber: "04",
          title: "Golf",
          src: "golf",
          description:
            "From one renaissance to another, playing golf on the banks of the Loire has always been a pleasure. The castles and golf courses that follow one another compete in beauty and architectural daring. And in the heart of its forests, where game abounds, nestles the Bordes course, one of the most beautiful golf courses in France.",
          imageSrc: "golf-acr",
          bgColor: "#408058",
        },
      ],
      ru: [
        {
          activityNumber: "01",
          title: "Замки",
          src: "castle",
          description:
            "Отель Au Charme Rabelaisien расположен в самом сердце региона Шато-де-ла-Луара посреди средневековых замков, королевских садов, на берегу величественной Луары.",
          imageSrc: "chambord-sunrise",
          bgColor: "#FB9149",
        },
        {
          activityNumber: "02",
          title: "Велосипед",
          src: "bicycle",
          description:
            "Путешествовать по маршруту Шато-де-ла-Луара, сплавляться по дикой Луаре или кататься на велосипеде вдоль ее берегов: есть много возможностей открыть регион, будь Вы с Вашей второй половинкой, с семьей или с друзьями",
          imageSrc: "bicycle-forest-landscape",
          bgColor: "#727378",
        },
        {
          activityNumber: "03",
          title: "Монгольфьер",
          src: "hot-air-balloon",
          description:
            "Полет на монтгольфьере над Луарой и средневековыми замками Амбуаза, Шенонсо и Шамбора. Получите волшебные впечатления от невероятного пейзажа",
          imageSrc: "hot-air-balloon-landscape",
          bgColor: "#A26848",
        },
        {
          activityNumber: "03",
          title: "Зоопарк Боваль",
          src: "beauval",
          description:
            "Менее чем в 30 минутах езды от Амбуаза находится зоопарк Боваль, в котором насчитывается около 800 видов животных. Это самая большая экспозиция животных в Европе. Некоторые виды по-настоящему экзотические и очень редкие",
          imageSrc: "beauval",
          bgColor: "#B0B0B0",
        },
        {
          activityNumber: "04",
          title: "Гольф",
          src: "golf",
          description:
            "Незабываема игра в гольф на берегу Луары. Замки и поля для гольфа, следующие друг за другом, соревнуются в красоте и архитектурной смелости. Леса, изобилующие дичью, окружают Бордес - одно из самых красивых полей для гольфа во Франции",
          imageSrc: "golf-acr",
          bgColor: "#408058",
        },
      ],
    },
  },
]

const ActivitiesContainer = ({
  activityTitle,
  updateActivityTitle,
  language,
  scrolltriggerReady,
  windowWidth,
}) => {
  let insideActivities = useRef()
  let outsideActivities = useRef()
  let fixBox = useRef(null)

  let firstTitle = textFixBox[0].listActivities[language][0].title

  const [scrollTo, setScrollTo] = useState(null)

  //tweens
  const fixboxTween = useRef(null)

  useEffect(() => {
    if (scrollTo) {
      const scrollbar = Scrollbar.get(document.getElementById("scroller-app"))
      scrollbar.scrollIntoView(document.getElementById(`${scrollTo}`), {
        offsetTop: 100,
      })
    }
  }, [scrollTo])

  useEffect(() => {
    const fixBoxConst = fixBox
    const insideActivitiesConst = insideActivities
    const outsideActivitiesConst = outsideActivities
    gsap.set(fixBoxConst, { yPercent: 30 })

    updateActivityTitle(firstTitle)
    if (scrolltriggerReady) {
      ScrollTrigger.getById("fixbox-activity-page") &&
        ScrollTrigger.getById("fixbox-activity-page").kill()
      fixboxTween.current = gsap.timeline({
        scrollTrigger: {
          id: "fixbox-activity-page",
          trigger: insideActivities,
          pin: fixBox,
          start: "10% 30%",
          endTrigger: outsideActivitiesConst,
          end: "80% 50%",
          toggleActions: "play none none reset",
        },
      })
    }
  }, [scrolltriggerReady])

  useEffect(() => {
    return () => {
      fixboxTween.current && fixboxTween.current.kill()
    }
  }, [])

  return (
    <div className="activities-container">
      <div className="row">
        <div className="col-sm-5 col-md-4 d-none d-sm-block">
          <div className="fix-box" ref={el => (fixBox = el)}>
            <div>
              {textFixBox.map((box, i) => {
                return (
                  <div
                    className="inside-box"
                    key={`${box.title[language]}-box-numebr-activities-${i}`}
                  >
                    <div className="title-div">
                      <div className="number">00{box.number}</div>
                      <div className="separation"></div>
                      <div className="title">{box.title[language]}</div>
                    </div>
                    <div className="activities-list">
                      {box.listActivities[language].map((activity, i) => {
                        return (
                          // <AnchorLink
                          //     to={`/activities#${activity.src}`}
                          //     key={`${activity.title}-anchor`}

                          // >
                          <div
                            className="activity"
                            onClick={() => setScrollTo(activity.src)}
                            // id={`${box.title}-activities-list-${i}`}
                            key={`${box.title}-activities-list-${i}`}
                            style={
                              activityTitle === activity.title
                                ? { opacity: "1" }
                                : null
                            }
                          >
                            {activityTitle === activity.title && (
                              <div className="separation"></div>
                            )}
                            <div className="title">{activity.title}</div>
                          </div>
                          // </AnchorLink>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="col-sm-7 col-md-8 sc-activities">
          <div className="" ref={el => (insideActivities = el)}>
            <div className="main-title-activity text-center d-block d-sm-none">
              Sur Place
            </div>
            {textFixBox[0].listActivities[language].map(box => {
              return (
                <InsideActivities
                  key={`${box.title}-activities`}
                  title={box.title}
                  description={box.description}
                  imageSrc={box.imageSrc}
                  color={box.bgColor}
                  src={box.src}
                />
              )
            })}
          </div>
          <div ref={el => (outsideActivities = el)}>
            <div className="main-title-activity text-center d-block d-sm-none">
              À l'Extérieur
            </div>
            {textFixBox[1].listActivities[language].map(box => {
              return (
                <InsideActivities
                  key={`${box.title}-activities`}
                  title={box.title}
                  description={box.description}
                  imageSrc={box.imageSrc}
                  color={box.bgColor}
                  src={box.src}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateActivityTitle: title =>
      dispatch({ type: `UPDATE_ACTIVITY_TITLE`, payload: title }),
  }
}

const mapStateToProps = ({
  activityTitle,
  language,
  scrolltriggerReady,
  windowWidth,
}) => {
  return { activityTitle, language, scrolltriggerReady, windowWidth }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesContainer)
